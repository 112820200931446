/* Container */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 10px;
    margin: 0 10px;
  }
  
  /* Button Items */
  .button-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .button-item i {
    margin-right: 15px;
  }
  
  .button-item span {
    flex-grow: 1;
  }
  
  /* Icons */
  .icon-flight:before {
    content: '\2708'; /* Unicode for airplane icon */
  }
  
  .icon-add:before {
    content: '\002B'; /* Unicode for plus sign */
  }
  
  .icon-doc:before {
    content: '\1F4C4'; /* Unicode for document icon */
  }
  
  /* Optional styles for ellipsis menu (if needed) */
  .button-item:after {
    content: '\22EE'; /* Unicode for vertical ellipsis */
    font-size: 18px;
  }

  /* Active state when clicked */
.button-item.active {
    transform: scale(1.025); /* Grow the button */
    transition: transform 0.3s ease-in-out; /* Add animation */
}

/* Spinner */
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey */
    border-top: 4px solid white; /* White spin section */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    position: absolute;
    right: 20px; /* Position to the right */
  }
  
  /* Keyframes for spinning */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* App Container */
.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Button to Open Modal */
  .open-modal-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal Box */
  .modal {
    background-color: white;
    width: 400px;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    animation: fadeIn 0.3s ease;
  }
  
  /* Modal Header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  /* Close Button */
  .close-modal-btn {
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
  
  /* Modal Body */
  .modal-body {
    padding: 15px 0;
  }
  
  /* Animation for Modal */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  
  